
import { Component, Prop, Vue } from 'vue-property-decorator';

import Page from '../views/Page.vue';

@Component({
	components: { Page }
})
export default class SimplePageLayout extends Vue{
	@Prop() pageTitle: string;
}
